<script>
import Form from './index.vue'

export default {
    name: "driverLearningEdit",
    components: {
        Form
    }
}
</script>

<template>
    <Form></Form>
</template>

<style scoped lang="scss">

</style>
